import { BiCalendarCheck, BiCalendarEdit } from 'react-icons/bi';
import BreadCrumbs from '@components/breadcrumbs/index.jsx';
import { Menu } from '../components/menu/menu.jsx';
import { PagePath } from '../pages-config';
export default function Reservas() {
  return (
    <>
      <BreadCrumbs
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Reservas', link: PagePath.Reservas },
        ]}
      />
      <Menu submenu className={'m-0 pl-0 pt-4'}>
        <Menu.Item
          to={PagePath.ReservasExternasPesquisar}
          title={'Conferência de reservas'}
          icon={<BiCalendarCheck className={'self-start'} />}
        />
        <Menu.Item
          to={PagePath.ReservasGerenciar}
          title={'Gestão de reservas'}
          icon={<BiCalendarEdit className={'self-start'} />}
        />
      </Menu>
    </>
  );
}
