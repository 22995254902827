import { forwardRef, useState } from 'react';
import { addMonths, compareAsc, isEqual } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { toBRTimeZone } from '@system/utils';
import { CalendarType } from './calendar-type.enum';
import { CalendarFooter } from './CalendarFooter';
import { MonthCalendar, MonthCalendarContainer } from './MonthCalendar';
import { MonthLabel, MonthLabelContainer } from './MonthLabel';
import { Tab, TabContainer } from './Tab';
import { YearCalendar, YearCalendarContainer } from './YearCalendar';

const CalendarStatus = {
  Empty: 0,
  Selected: 2,
};
const NavDirection = {
  Prev: 0,
  Next: 1,
};

export const CalendarSelector = forwardRef(function CalendarSelector(
  {
    calendarType,
    className,
    interval,
    changeOnly,
    multiCalendar,
    onTypeSwitch,
    startCalendar,
    selectedMonths,
    onReset,
    onDateChange,
    onMonthsChange,
    onApply,
    startDay,
    monthStart,
    disableResume = false,
  },
  ref,
) {
  const [status, setStatus] = useState(getInitialStatus);
  const [activeCalendar, setActiveCalendar] = useState(calendarType);
  const [monthsSeq, setMonthsSeq] = useState(monthStart);
  const startMonth = toBRTimeZone(startCalendar || new Date());
  const nextMonth = addMonths(startMonth, 1);
  const monthCalendars = [{ date: startMonth }, { date: nextMonth }];

  function getInitialStatus() {
    const isIntervalSelected = interval.start && interval.end;
    const isStartSelected = changeOnly === 'start' && interval.start;
    const isEndSelected = changeOnly === 'end' && interval.end;

    return isIntervalSelected || isStartSelected || isEndSelected
      ? CalendarStatus.Selected
      : CalendarStatus.Empty;
  }

  function handleTypeSwitch(type) {
    setActiveCalendar(type);
    handleReset();
    onTypeSwitch(type);
  }

  function handleMonthNavigation(direction) {
    if (direction === NavDirection.Prev)
      setMonthsSeq(monthsSeq - monthCalendars.length);
    else setMonthsSeq(monthsSeq + monthCalendars.length);
  }

  function handleSelectedDateChange(date) {
    const isStartAhead = interval.start > date;
    console.log('interval', interval);
    if (isStartAhead && changeOnly === 'end') return;

    setStatus(CalendarStatus.Selected);
    onDateChange(date);
  }

  function handleSelectMonth(month) {
    setStatus(CalendarStatus.Selected);
    onMonthsChange([...selectedMonths, month].sort(compareAsc));
  }

  function handleDeselectMonth(month) {
    setStatus(CalendarStatus.Selected);
    onMonthsChange(selectedMonths.filter((m) => !isEqual(m, month)));
  }

  function handleReset() {
    setStatus(CalendarStatus.Empty);
    onReset();
  }

  function handleApply() {
    setStatus(CalendarStatus.Selected);
    onApply();
  }

  return (
    <div className={twMerge(`w-full`, className)}>
      <div
        className="w-full rounded-md border border-solid border-neutral-400 bg-white p-0 shadow"
        ref={ref}
      >
        {multiCalendar && (
          <TabContainer>
            <Tab
              active={activeCalendar === CalendarType.Monthly}
              onClick={() => handleTypeSwitch(CalendarType.Monthly)}
            >
              Data exata
            </Tab>
            <Tab
              active={activeCalendar === CalendarType.Yearly}
              onClick={() => handleTypeSwitch(CalendarType.Yearly)}
            >
              Por mês
            </Tab>
          </TabContainer>
        )}
        {activeCalendar === CalendarType.Monthly && (
          <>
            <MonthLabelContainer
              disablePrev={monthsSeq <= 0}
              onPrevClick={() => handleMonthNavigation(NavDirection.Prev)}
              onNextClick={() => handleMonthNavigation(NavDirection.Next)}
            >
              {monthCalendars.map((calendar, idx) => {
                return (
                  <MonthLabel
                    key={idx}
                    date={addMonths(calendar.date, monthsSeq)}
                  />
                );
              })}
            </MonthLabelContainer>
            <MonthCalendarContainer>
              {monthCalendars.map((calendar, idx) => (
                <MonthCalendar
                  key={idx}
                  date={addMonths(calendar.date, monthsSeq)}
                  interval={interval}
                  startDay={startDay}
                  onChange={handleSelectedDateChange}
                />
              ))}
            </MonthCalendarContainer>
          </>
        )}
        {activeCalendar === CalendarType.Yearly && (
          <YearCalendarContainer>
            <YearCalendar
              selectedMonths={selectedMonths}
              onSelect={handleSelectMonth}
              onDeselect={handleDeselectMonth}
            />
          </YearCalendarContainer>
        )}
        <CalendarFooter
          interval={interval}
          months={selectedMonths}
          disableResume={disableResume}
          resumeType={activeCalendar}
          disabled={status === CalendarStatus.Empty}
          onReset={handleReset}
          onApply={handleApply}
        />
      </div>
    </div>
  );
});
