import { useAccessControl } from '@system/acl';
import { AiOutlineFlag } from 'react-icons/ai';
import {
  BiBus,
  BiCalendarAlt,
  BiCalendarCheck,
  BiCalendarEdit,
} from 'react-icons/bi';
import { BsFileText, BsSuitcase } from 'react-icons/bs';
import { FaHandHoldingDollar } from 'react-icons/fa6';
import { GiCommercialAirplane } from 'react-icons/gi';
import { IoSettingsOutline, IoBusinessSharp } from 'react-icons/io5';
import { LiaFileInvoiceDollarSolid, LiaUserEditSolid } from 'react-icons/lia';
import { LuHotel } from 'react-icons/lu';
import {
  MdOutlineAirlineSeatReclineNormal,
  MdOutlineConnectingAirports,
  MdOutlineEmojiFoodBeverage,
  MdOutlineHotelClass,
  MdOutlineLocalOffer,
} from 'react-icons/md';
import {
  PiAirplaneTiltThin,
  PiBuildingsBold,
  PiCallBellLight,
  PiCaretRight,
  PiUsers,
} from 'react-icons/pi';
import { TbReportMoney } from 'react-icons/tb';
import { Link, useLocation } from 'react-router-dom';
import { PagePath, pagesACL } from '../pages-config';

function Sidebar() {
  const { hasPermission } = useAccessControl();
  const location = useLocation();

  const parentPage = '/' + location.pathname.split('/')[1];

  return (
    <>
      <div
        className="min-h-[calc(100vh-48px)] divide-x-0 divide-y divide-solid divide-transparent-white bg-primary-900 pt-5 text-sm"
        id="sidebar"
      >
        {hasPermission(pagesACL[PagePath.Aereo]) && (
          <Link
            to={PagePath.Aereo}
            relative="path"
            className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${parentPage === PagePath.Aereo ? 'opacity-100' : 'opacity-60'}`}
          >
            <GiCommercialAirplane className="text-2xl" title="Aéreo saidas" />{' '}
            <PiCaretRight className="hidden xl:block" />{' '}
            <span className="hidden w-44 text-left xl:block">Aéreo</span>
          </Link>
        )}

        <div
          id="subLinksAereo"
          className={
            parentPage !== PagePath.Aereo
              ? 'hidden'
              : 'divide-x-0 divide-y divide-solid divide-transparent-white'
          }
        >
          {hasPermission(pagesACL[PagePath.AereoFrota]) && (
            <Link
              to={PagePath.AereoFrota}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AereoFrota ? 'opacity-100' : 'opacity-60'}`}
            >
              <PiAirplaneTiltThin className="text-2xl" title="Frota" />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">Frota</span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AereoSaidas]) && (
            <Link
              to={PagePath.AereoSaidas}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AereoSaidas ? 'opacity-100' : 'opacity-60'}`}
            >
              <MdOutlineLocalOffer className="flip text-2xl" title="Saídas" />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">Saídas</span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AereoReservas]) && (
            <Link
              to={PagePath.AereoReservas}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AereoReservas ? 'opacity-100' : 'opacity-60'}`}
            >
              <PiCallBellLight className="text-2xl" title="Reserva" />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">Reservas</span>
            </Link>
          )}
        </div>

        {hasPermission(pagesACL[PagePath.Hotel]) && (
          <Link
            to={PagePath.Hotel}
            relative="path"
            className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${parentPage === PagePath.Hotel ? 'opacity-100' : 'opacity-60'}`}
          >
            <LuHotel className="text-2xl" title="Hotel" />{' '}
            <PiCaretRight className="hidden xl:block" />{' '}
            <span className="hidden w-44 text-left xl:block">Hotel</span>
          </Link>
        )}

        {hasPermission(pagesACL[PagePath.Rodoviario]) && (
          <Link
            to={PagePath.Rodoviario}
            relative="path"
            className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${parentPage === PagePath.Rodoviario ? 'opacity-100' : 'opacity-60'}`}
          >
            <BiBus className="text-2xl" title="Rodoviário" />{' '}
            <PiCaretRight className="hidden xl:block" />{' '}
            <span className="hidden w-44 text-left xl:block">Rodoviário</span>
          </Link>
        )}

        {hasPermission(pagesACL[PagePath.PacoteFixo]) && (
          <Link
            to={PagePath.PacoteFixo}
            relative="path"
            className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${parentPage === PagePath.PacoteFixo ? 'opacity-100' : 'opacity-60'}`}
          >
            <BsSuitcase className="text-2xl" title="Pacote" />{' '}
            <PiCaretRight className="hidden xl:block" />{' '}
            <span className="hidden w-44 text-left xl:block">Pacote</span>
          </Link>
        )}

        {hasPermission(pagesACL[PagePath.Admin]) && (
          <Link
            to={PagePath.Admin}
            relative="path"
            className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${parentPage === PagePath.Admin ? 'opacity-100' : 'opacity-60'}`}
          >
            <IoSettingsOutline className="text-2xl" title="Configurações" />{' '}
            <PiCaretRight className="hidden xl:block" />{' '}
            <span className="hidden w-44 text-left xl:block">
              Configurações
            </span>
          </Link>
        )}

        <div
          id="subLinksAdmin"
          className={
            parentPage !== PagePath.Admin
              ? 'hidden'
              : 'divide-x-0 divide-y divide-solid divide-transparent-white'
          }
        >
          {hasPermission(pagesACL[PagePath.AdminRegrasTarifarias]) && (
            <Link
              to={PagePath.AdminRegrasTarifarias}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AdminRegrasTarifarias ? 'opacity-100' : 'opacity-60'}`}
            >
              <TbReportMoney className="text-2xl" title="Companhias" />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">
                Regras Tarifárias
              </span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AdminEmpresas]) && (
            <Link
              to={PagePath.AdminEmpresas}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AdminEmpresas ? 'opacity-100' : 'opacity-60'}`}
            >
              <IoBusinessSharp className="text-2xl" title="Agência/Cliente" />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">Empresas</span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AdminCompanhias]) && (
            <Link
              to={PagePath.AdminCompanhias}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AdminCompanhias ? 'opacity-100' : 'opacity-60'}`}
            >
              <AiOutlineFlag className="text-2xl" title="Companhias" />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">
                Cias. Aéreas
              </span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AdminAeroportos]) && (
            <Link
              to={PagePath.AdminAeroportos}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AdminAeroportos ? 'opacity-100' : 'opacity-60'}`}
            >
              <MdOutlineConnectingAirports
                className="text-2xl"
                title="Aeroportos"
              />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">Aeroportos</span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AdminAssentos]) && (
            <Link
              to={PagePath.AdminAssentos}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AdminAssentos ? 'opacity-100' : 'opacity-60'}`}
            >
              <MdOutlineAirlineSeatReclineNormal
                className="text-2xl"
                title="Caract. Assentos"
              />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">
                Caract. Assentos
              </span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AdminClasses]) && (
            <Link
              to={PagePath.AdminClasses}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AdminClasses ? 'opacity-100' : 'opacity-60'}`}
            >
              <MdOutlineHotelClass
                className="text-2xl"
                title="Caract. Classes"
              />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">
                Caract. Classes
              </span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AdminFabricantes]) && (
            <Link
              to={PagePath.AdminFabricantes}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AdminFabricantes ? 'opacity-100' : 'opacity-60'}`}
            >
              <PiBuildingsBold className="text-2xl" title="Fabricante" />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">
                Fabricantes
              </span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AdminServicosBordo]) && (
            <Link
              to={PagePath.AdminServicosBordo}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AdminServicosBordo ? 'opacity-100' : 'opacity-60'}`}
            >
              <MdOutlineEmojiFoodBeverage
                className="text-2xl"
                title="Serviço de Bordo"
              />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">
                Serviços de Bordo
              </span>
            </Link>
          )}

          {hasPermission(pagesACL[PagePath.AdminUsuarios]) && (
            <Link
              to={PagePath.AdminUsuarios}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.AdminUsuarios ? 'opacity-100' : 'opacity-60'}`}
            >
              <PiUsers className="text-2xl" title="Usuários" />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">Usuários</span>
            </Link>
          )}
        </div>

        {hasPermission(pagesACL[PagePath.Relatorios]) && (
          <Link
            to={PagePath.Relatorios}
            relative="path"
            className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${parentPage === PagePath.Relatorios ? 'opacity-100' : 'opacity-60'}`}
          >
            <BsFileText className="text-2xl" title="Relatórios" />{' '}
            <PiCaretRight className="hidden xl:block" />{' '}
            <span className="hidden w-44 text-left xl:block">Relatórios</span>
          </Link>
        )}

        {hasPermission(pagesACL[PagePath.Financeiro]) && (
          <Link
            to={PagePath.Financeiro}
            relative="path"
            className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${parentPage === PagePath.Financeiro ? 'opacity-100' : 'opacity-60'}`}
          >
            <LiaFileInvoiceDollarSolid
              className="text-2xl"
              title="Financeiro"
            />{' '}
            <PiCaretRight className="hidden xl:block" />{' '}
            <span className="hidden w-44 text-left xl:block">Financeiro</span>
          </Link>
        )}
        <div
          id="subLinksAdmin"
          className={
            parentPage !== PagePath.Financeiro
              ? 'hidden'
              : 'divide-x-0 divide-y divide-solid divide-transparent-white'
          }
        >
          {hasPermission(pagesACL[PagePath.FinanceiroRecebiveis]) && (
            <Link
              to={PagePath.FinanceiroRecebiveis}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.FinanceiroRecebiveis ? 'opacity-100' : 'opacity-60'}`}
            >
              <FaHandHoldingDollar className="text-2xl" title="Finanças" />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">
                Gestão de recebíveis
              </span>
            </Link>
          )}
        </div>

        {hasPermission(pagesACL[PagePath.Reservas]) && (
          <Link
            to={PagePath.Reservas}
            relative="path"
            className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${parentPage === PagePath.Reservas ? 'opacity-100' : 'opacity-60'}`}
          >
            <BiCalendarAlt className="text-2xl" title="Configurações" />{' '}
            <PiCaretRight className="hidden xl:block" />{' '}
            <span className="hidden w-44 text-left xl:block">Reservas</span>
          </Link>
        )}
        <div
          id="subLinksAdmin"
          className={
            parentPage !== PagePath.Reservas
              ? 'hidden'
              : 'divide-x-0 divide-y divide-solid divide-transparent-white'
          }
        >
          {hasPermission(pagesACL[PagePath.ReservasExternasPesquisar]) && (
            <Link
              to={PagePath.ReservasExternasPesquisar}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.ReservasExternasPesquisar ? 'opacity-100' : 'opacity-60'}`}
            >
              <BiCalendarCheck
                className="text-2xl"
                title={PagePath.ReservasExternasPesquisar}
              />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">
                Conferência de reservas
              </span>
            </Link>
          )}
          {hasPermission(pagesACL[PagePath.ReservasGerenciar]) && (
            <Link
              to={PagePath.ReservasGerenciar}
              relative="path"
              className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${location.pathname === PagePath.ReservasGerenciar ? 'opacity-100' : 'opacity-60'}`}
            >
              <BiCalendarEdit
                className="text-2xl"
                title={PagePath.ReservasGerenciar}
              />{' '}
              <PiCaretRight className="hidden xl:block" />{' '}
              <span className="hidden w-44 text-left xl:block">
                Gestão de reservas
              </span>
            </Link>
          )}
        </div>

        <Link
          to={PagePath.MeusDados}
          relative="path"
          className={`flex items-center gap-2 p-3.5 font-light text-white hover:text-white hover:opacity-100 ${parentPage === PagePath.MeusDados ? 'opacity-100' : 'opacity-60'}`}
        >
          <LiaUserEditSolid className="text-2xl" title="Meus Dados" />{' '}
          <PiCaretRight className="hidden xl:block" />{' '}
          <span className="hidden w-44 text-left xl:block">Meus Dados</span>
        </Link>
      </div>
    </>
  );
}

export default Sidebar;
