import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

/**
 * @param {Date} date
 */
export const getCalendarDays = (date) => {
  const startMonth = startOfMonth(date);
  const endMonth = endOfMonth(date);
  const startCalendar = startOfWeek(startMonth, { weekStartsOn: 0 }); // Assuming the week starts on Sunday
  const endCalendar = endOfWeek(endMonth, { weekStartsOn: 0 });

  let days = eachDayOfInterval({ start: startCalendar, end: endCalendar });

  // If the number of days is not 35, adjust with days of the next month
  while (days.length < 35) {
    days.push(addDays(endCalendar, days.length - 34));
  }

  return days;
};
