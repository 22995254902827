import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

const button = tv({
  slots: {
    base: 'm-0 w-max bg-secondary hover:bg-secondary-900 disabled:bg-neutral-400 disabled:hover:bg-neutral-400',
    text: 'text-white disabled:text-white',
  },
  variants: {
    default: {
      base: 'bg-secondary hover:bg-secondary-900 disabled:bg-neutral-400 disabled:hover:bg-neutral-400',
      text: 'text-white disabled:text-white',
    },
    type: {
      ghost: {
        base: 'bg-white border-solid border-secondary border-1 hover:bg-secondary-100 disabled:border-neutral-400 disabled:bg-neutral-100',
        text: 'text-secondary group-disabled:text-neutral-600',
      },
      link: {
        base: 'bg-transparent disabled:bg-transparent border-none hover:bg-transparent disabled:hover:bg-transparent',
        text: 'text-secondary hover:text-secondary-900 group-disabled:text-neutral-600',
      },
      small: {
        base: 'm-0 h-9 px-4 py-2 bg-secondary hover:bg-secondary-900 disabled:bg-neutral-400 disabled:hover:bg-neutral-400',
        text: '!text-body !font-semibold text-white disabled:text-white',
      },
      smallLink: {
        base: 'm-0 h-9 px-4 py-2 bg-transparent disabled:bg-transparent border-none hover:bg-transparent disabled:hover:bg-transparent',
        text: '!text-body !font-semibold text-secondary hover:text-secondary-900 group-disabled:text-neutral-600',
      },
    },
  },
});

export function Button({
  variant = 'default',
  icon: Icon,
  iconPosition = 'left',
  label,
  className,
  labelClassName,
  type = 'button',
  ...props
}) {
  const { base, text } = button({ type: variant });

  return (
    <button
      className={twMerge(
        'group',
        base({ class: className }),
        iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
      )}
      type={type}
      {...props}
    >
      {Icon && <Icon className={twMerge(text(), labelClassName)} size={22} />}
      <span
        className={twMerge(
          'text-center !text-heading-3',
          labelClassName,
          text(),
        )}
      >
        {label}
      </span>
    </button>
  );
}
