import { FaHandHoldingDollar } from 'react-icons/fa6';
import BreadCrumbs from '@components/breadcrumbs/index.jsx';
import { useAccessControl } from '@system/acl';
import { Menu } from '../components/menu/menu.jsx';
import { PagePath, pagesACL } from '../pages-config';

export default function Financeiro() {
  const { hasPermission } = useAccessControl();

  return (
    <>
      <BreadCrumbs
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Financeiro', link: PagePath.Aereo },
        ]}
      />
      <Menu submenu className={'m-0 pl-0 pt-4'}>
        {hasPermission(pagesACL[PagePath.FinanceiroRecebiveis]) && (
          <Menu.Item
            to={PagePath.FinanceiroRecebiveis}
            title={'Gestão de recebíveis'}
            icon={<FaHandHoldingDollar className={'self-start'} />}
          />
        )}
      </Menu>
    </>
  );
}
