import { forwardRef, useEffect, useRef, useState } from 'react';
import { CalendarSelector } from '@components/calendar-selector';
import { CalendarType } from '@components/calendar-selector/calendar-type.enum';
import { InputText } from '@components/input/InputText';

const CalendarStatus = {
  Invisible: 'invisible',
  Visible: 'visible',
};

export const InputDatePicker = forwardRef(function InputDatePicker(
  {
    calendarType = CalendarType.Monthly,
    value,
    intervalStart,
    intervalEnd,
    changeOnly,
    multiCalendar,
    onTypeSwitch,
    startCalendar,
    selectedMonths,
    onClick,
    onDateChange,
    onMonthsChange,
    startDay,
    monthStart = 0,
    disableResume = false,
    startPosition = 'left',
    ...props
  },
  ref,
) {
  const calendarRef = useRef(null);
  const [calendarStatus, setCalendarStatus] = useState(
    CalendarStatus.Invisible,
  );
  const isCalendarVisible = calendarStatus === CalendarStatus.Visible;
  const calendarOpacityClass = isCalendarVisible
    ? 'visible opacity-100'
    : 'invisible opacity-0';
  const startPositionClass = startPosition === 'left' ? 'left-0' : 'right-0';

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        isCalendarVisible &&
        calendarRef.current &&
        !calendarRef.current.contains(e.target)
      ) {
        setCalendarStatus(CalendarStatus.Invisible);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCalendarVisible]);

  function makeInterval() {
    return {
      start: intervalStart,
      end: intervalEnd,
    };
  }

  function handleClick() {
    if (onClick) onClick(() => setCalendarStatus(CalendarStatus.Visible));
    else setCalendarStatus(CalendarStatus.Visible);
  }

  function handleDateChange(value) {
    onDateChange(value);
    if (changeOnly === 'start') setCalendarStatus(CalendarStatus.Invisible);
  }

  function handleMonthsChange(value) {
    onMonthsChange(value);
  }

  function handleCalendarReset() {
    onDateChange(null);
    onMonthsChange([]);
  }

  function handleCalendarApply() {
    setCalendarStatus(CalendarStatus.Invisible);
  }

  return (
    <div className="relative w-full">
      <InputText
        {...props}
        value={value}
        onChange={() => {}} // Prevents the input from being controlled
        readOnly
        onClick={handleClick}
        ref={ref}
      />
      <CalendarSelector
        calendarType={calendarType}
        aria-hidden={!isCalendarVisible}
        className={`top-19 absolute z-10 w-[732px] pb-10 ${startPositionClass} ${calendarOpacityClass}`}
        interval={makeInterval()}
        changeOnly={changeOnly}
        disableResume={disableResume}
        multiCalendar={multiCalendar}
        onTypeSwitch={onTypeSwitch}
        startCalendar={startCalendar}
        selectedMonths={selectedMonths}
        onDateChange={handleDateChange}
        onMonthsChange={handleMonthsChange}
        onReset={handleCalendarReset}
        onApply={handleCalendarApply}
        monthStart={monthStart}
        ref={calendarRef}
        startDay={startDay}
      />
    </div>
  );
});
