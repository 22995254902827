import { createContext, useCallback, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const AereoReservationContext = createContext({});

const AereoReservationProvider = ({ children }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [queryType, setQueryType] = useState('initial');

  const [searchParams] = useSearchParams();

  const params = {};
  for (let [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  const closeForm = useCallback(() => {
    setIsFormOpen(false);
  }, []);

  const openForm = useCallback(() => {
    setIsFormOpen(true);
  }, []);

  const handleUpdateQueryType = useCallback((queryType) => {
    setQueryType(queryType);
  }, []);

  return (
    <AereoReservationContext.Provider
      value={{
        isFormOpen,
        openForm,
        closeForm,
        queryType,
        handleUpdateQueryType,
        params,
        searchParams,
      }}
    >
      {children}
    </AereoReservationContext.Provider>
  );
};

export default AereoReservationProvider;

// eslint-disable-next-line react-refresh/only-export-components
export const useAereoReservation = () => {
  const context = useContext(AereoReservationContext);

  if (context === undefined)
    throw new Error(
      'useAereoReservation must be used within an AereoReservationProvider',
    );

  return context;
};
