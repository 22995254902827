import { useState } from 'react';
import { BsFillSuitcase2Fill } from 'react-icons/bs';
import { PiCaretDownBold, PiCaretUpBold } from 'react-icons/pi';
import { TbPlane } from 'react-icons/tb';
import dayjs from 'dayjs';
import { useBookings } from '@stores/bookings';
import { formatAirportScale, formatScale } from '@system/utils';

const FlightDetailItem = ({ type = 'outbound' }) => {
  const { flightDetail } = useBookings();
  if (!flightDetail) return false;
  const variants = {
    outbound: {
      title: 'Ida',
      route: flightDetail.outbound,
      date:
        flightDetail.outboundDate &&
        dayjs(flightDetail.outboundDate).format('DD/MM/YYYY'),
    },
    'return-flight': {
      title: 'Volta',
      route: flightDetail.return,
      date:
        flightDetail.returnDate &&
        dayjs(flightDetail.returnDate).format('DD/MM/YYYY'),
    },
    'one-way': {
      title: '1 Trecho',
      route: flightDetail.outbound,
      date:
        flightDetail.outboundDate &&
        dayjs(flightDetail.outboundDate).format('DD/MM/YYYY'),
    },
  };
  const info = variants[type] ?? ' - ';
  return (
    <div className="w-full">
      {/* badge */}
      <span className="  rounded-md bg-info-100 px-2.5 py-1.5">
        <TbPlane
          className={`text-primary ${type === 'return-flight' ? 'rotate-180' : ''}`}
        />
        <span className="ml-1.5 text-small font-medium text-primary">
          {info?.title}: {info?.date}
        </span>
      </span>
      <div className="flex flex-row items-center gap-x-3 pb-2.5 pt-4">
        <img
          className={'imgCia !m-0 !h-4'}
          src={info.route.fleet.airline.logo}
        />{' '}
        <span className="w-full text-small font-normal">
          Operado por {info.route.fleet.airline?.surname}&nbsp;
          {info.route.flightNumber ? ` - Voo ${info.route.flightNumber}` : ''}
        </span>
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col items-start">
          <span className="flex gap-x-1.5 text-left text-heading-3 text-primary">
            {info.route.origin}{' '}
            <span className="font-normal text-neutral-800">
              {info.route.departure?.substring(0, 5)}
            </span>
          </span>
          <div className="mt-0.5 text-left text-small font-normal text-neutral-700">
            {info.route.airport.origin.city}/{info.route.airport.origin.state}
          </div>
        </div>
        <div className="flex h-full flex-row items-center">
          <div className="flex flex-col items-center justify-center text-nowrap text-center text-xs">
            {formatScale(
              info?.route?.layovers?.length ?? 0,
              info?.route?.connections?.length ?? 0,
            )}

            <hr className="mt-1 w-[60px] border-neutral-700" />
          </div>
          <div className="">
            {Array.from({
              length: formatAirportScale(
                0,
                0,
                0,
                info?.route?.connections?.length ?? 0,
                info?.route?.layovers?.length ?? 0,
              ),
            }).map((_, index) => (
              <div
                key={index}
                className="z-0 -mt-[33px] h-2 w-2 rounded-full !border !border-solid !border-neutral-700 bg-white"
              />
            ))}
          </div>
        </div>
        <div>
          <span className="flex gap-x-1.5 text-heading-3 text-primary">
            {info.route.destination}
            <span className="font-normal text-neutral-800">
              {info.route.arrival.substring(0, 5)}
            </span>
          </span>
          <div className="mt-0.5 text-left text-small font-normal text-neutral-700">
            {info.route.airport.destination.city}/
            {info.route.airport.destination.state}
          </div>
        </div>
      </div>
    </div>
  );
};

const FlightDetails = () => {
  const { flightDetail } = useBookings();
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);
  const hasBaggage = flightDetail?.bagagem?.length > 0;
  const [qtyBaggage, weight] = flightDetail?.bagagem?.split('-') ?? [];
  return (
    <div className={'flex flex-col rounded-lg bg-white p-4'}>
      <div
        className={
          'flex cursor-pointer select-none flex-row items-center justify-between border-0 !border-b border-solid border-neutral-300 pb-3'
        }
        onClick={toggle}
      >
        <span className="text-heading-3 text-primary">Detalhes do voo</span>
        {!collapse ? (
          <PiCaretUpBold className="text-primary" size={'1.125rem'} />
        ) : (
          <PiCaretDownBold className="text-primary" size={'1.125rem'} />
        )}
      </div>

      {!collapse && flightDetail && (
        <div
          className={
            'm-0 flex w-full list-none flex-col content-start  items-start justify-start gap-y-4 p-0  pt-3'
          }
        >
          <FlightDetailItem
            type={!flightDetail?.return ? 'one-way' : 'outbound'}
          />
          {flightDetail?.return && <FlightDetailItem type="return-flight" />}
          {hasBaggage && (
            <div className="mt-2 flex flex-row items-center justify-start gap-2">
              <BsFillSuitcase2Fill className="text-primary" />
              <span className="text-small font-normal text-neutral-800">
                Bagagem para despachar: &nbsp;
                {qtyBaggage} de {weight}kg
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FlightDetails;
