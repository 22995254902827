import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '@services/api';
import { toaster } from '@system/utils/toaster';
import '../components/Login.css';
const schema = yup.object().shape({
  name: yup.string().required('Nome completo é obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  agency: yup.string().required('Nome da Agência de Turismo é obrigatório'),
});

const NovoUsuario = () => {
  const [loading, setLoading] = useState(false);
  const [envioEmail, setEnvioEmail] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const validaForm = async (data) => {
    setLoading(true);
    try {
      const response = await api.post('/leads', data);
      if (response.status === 201) {
        setLoading(false);
        setEnvioEmail(true);
      }
    } catch (error) {
      setLoading(false);
      toaster('error', error.message);
    }
  };

  return (
    <div id="login">
      <div className="col01">
        <div className="bg_01"></div>
      </div>

      <div className="col02">
        <div className="formLogin">
          <div className="book2bLogin" alt="book2b">
            {' '}
          </div>

          <Form
            className={envioEmail ? 'hide' : 'inputFormLogin'}
            onSubmit={handleSubmit(validaForm)}
          >
            <div>
              <h2>Saiba mais sobre a plataforma.</h2>
              <p>Por favor, digite seus dados.</p>
            </div>
            <Form.Group className="pb-2">
              <Form.Label htmlFor="name">Nome Completo</Form.Label>
              <InputV2
                id="name"
                placeholder="Digite seu nome completo"
                {...register('name')}
              />

              <Form.Message error={errors?.name} />
            </Form.Group>

            <Form.Group className="pb-2">
              <Form.Label>E-mail</Form.Label>
              <InputV2
                name="email"
                type="email"
                {...register('email')}
                placeholder="Digite seu e-mail"
              />
              <Form.Message error={errors?.email} />
            </Form.Group>

            <Form.Group className="pb-2">
              <Form.Label>Agência de Turismo</Form.Label>
              <InputV2
                name="agency"
                type="text"
                {...register('agency')}
                placeholder="Digite o nome da Agência de Turismo"
              />
              <Form.Message error={errors.agency} />
            </Form.Group>
            <Form.Group className="mt-2">
              <ButtonV2 size="large" className="w-full" loading={loading}>
                <ButtonV2.Text>Enviar</ButtonV2.Text>
              </ButtonV2>
            </Form.Group>
          </Form>

          <div className={envioEmail ? 'mb-5 mt-5 ' : 'hide'}>
            <h2>Obrigado!</h2>
            <p></p>
            <p>Entraremos em contato em breve.</p>
            <div className="row ">
              <div className="col-4"></div>
              <div className="col-4">
                <Link to="/" relative="path">
                  <ButtonV2 ghost>
                    <ButtonV2.Text>Voltar</ButtonV2.Text>
                  </ButtonV2>
                </Link>
              </div>
              <div className="col-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NovoUsuario;
