import {
  isBefore,
  isEqual,
  isSameMonth,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { getCalendarDays } from './utils';

const DayLabel = ({ children }) => (
  <span className="inline-flex items-center justify-center py-1 text-center text-small font-semibold">
    {children}
  </span>
);
const Day = ({
  children,
  disabled = false,
  checked = false,
  isEdge = false,
  onClick,
}) => (
  <div
    role="button"
    aria-disabled={disabled}
    aria-checked={checked}
    className="relative inline-flex h-10 items-center justify-center rounded-md border border-solid border-neutral-300 py-2 text-center text-small text-neutral-800 hover:bg-white aria-checked:bg-secondary-100 aria-disabled:cursor-default aria-disabled:bg-neutral-100 aria-disabled:text-neutral-600"
    onClick={onClick}
  >
    {children}
    <span
      aria-hidden={!isEdge}
      className="left-[calc(50% - 27px)] absolute top-0 flex h-[38px] w-[38px] items-center justify-center rounded-[50%] border-0 border-solid border-secondary bg-secondary text-white duration-0 hover:flex hover:border aria-hidden:bg-transparent aria-hidden:text-neutral-800"
    >
      {children}
    </span>
  </div>
);

export const MonthCalendar = ({
  date = new Date(),
  interval,
  onChange,
  startDay = new Date(),
}) => {
  const days = getCalendarDays(date);

  function isChecked(day) {
    return isWithinInterval(day, {
      start: interval.start,
      end: interval.end || interval.start,
    });
  }

  function isEdge(day) {
    return isEqual(day, interval.start) || isEqual(day, interval.end);
  }

  function isDisabled(day) {
    return !isSameMonth(date, day) || isBefore(day, startOfDay(startDay));
  }

  function handleClick(day) {
    if (isDisabled(day)) return;
    onChange(day);
  }

  return (
    <div className="grid basis-1/2 grid-cols-7 grid-rows-7 place-content-stretch gap-0.5 pb-3">
      {['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'].map((label) => (
        <DayLabel key={label}>{label}</DayLabel>
      ))}

      {days.map((day, idx) => (
        <Day
          key={idx}
          disabled={isDisabled(day)}
          checked={isChecked(day)}
          isEdge={isEdge(day)}
          onClick={() => handleClick(day)}
        >
          {day.getDate()}
        </Day>
      ))}
    </div>
  );
};

export const MonthCalendarContainer = ({ children }) => (
  <div className="flex gap-x-7 px-5">{children}</div>
);
