import { FlightRulesTexts } from '@system/enums/flight-rules-texts';

export function getCancelRuleText(rule) {
  let text = '';

  if (rule.cancelWithCost && rule.cancelCostPercentage) {
    text = cancellationWithCust(rule.cancelCostPercentage);
  } else if (rule.cancelWithRefund && rule.refundWithDeadline) {
    text = cancellationWithDateAndRefund(
      rule.refundPercentage,
      rule.refundPercentageDeadline,
      rule.refundDaysLimit,
    );
  } else if (rule.cancelWithRefund && rule.refundPercentage) {
    text = cancellationWithRefund(rule.refundPercentage);
  } else {
    text = cancellationWithoutCust();
  }

  return text;
}

export function getChangeRuleText(rule) {
  let text = '';

  if (rule.changeWithCost && rule.changeLimitDays) {
    text = alterationWithDateAndCust(
      rule.changeLimitDays,
      rule.changeCostValue,
    );
  } else {
    text = alterationWithCust(rule.changeCostValue);
  }

  return text;
}

function cancellationWithCust(cust) {
  return FlightRulesTexts.CANCELLATION_WITH_CUST.replace(
    '$cust$',
    Number(cust) ?? '',
  );
}

function cancellationWithoutCust() {
  return FlightRulesTexts.CANCELLATION_WITHOUT_CUST;
}

function cancellationWithRefund(refund) {
  return FlightRulesTexts.CANCELLATION_WITH_REFUND.replace(
    '$refund$',
    Number(refund) ?? '',
  );
}

function cancellationWithDateAndRefund(refundBefore, refundAfter, daysLimit) {
  return FlightRulesTexts.CANCELLATION_WITH_DATE_AND_REFUND.replace(
    '$refundBefore$',
    Number(refundBefore) ?? '',
  )
    .replace('$day-01$', daysLimit)
    .replace('$day-02$', daysLimit)
    .replace('$refundAfter$', Number(refundAfter) ?? '');
}

function alterationWithDateAndCust(daysLimit, cust) {
  return FlightRulesTexts.ALTERATION_WITH_DATE_AND_CUST.replace(
    '$day-01$',
    daysLimit,
  )
    .replace('$day-02$', daysLimit)
    .replace('$cust$', cust);
}

function alterationWithCust(cust) {
  return FlightRulesTexts.ALTERATION_WITH_CUST.replace('$cust$', cust);
}
